import React from "react";
import {
  TermsContainer,
  TermsHeader,
  TermsContent,
} from "./Privacies.elements";

// Subcomponents for Privacy Policy Sections
const PrivacySection = ({ title, children }) => (
  <section style={{ marginBottom: "1em" }}>
    <h3>{title}</h3>
    {children}
  </section>
);

const PrivacyPolicy = () => {
  return (
    <div style={{ marginTop: "1em" }}>
      <p>
        <strong>آخر تحديث:</strong> 20 سبتمبر 2024
      </p>

      <PrivacySection title="مقدمة">
        <p>
          مرحبًا بكم في FluentFox. نحن نقدر خصوصيتك وملتزمون بحماية معلوماتك
          الشخصية. توضح سياسة الخصوصية هذه كيفية جمعنا واستخدامنا وكشفنا وحماية
          بياناتك عند استخدامك لتطبيقنا المحمول.
        </p>
      </PrivacySection>

      <PrivacySection title="المعلومات التي نجمعها">
        <p>قد نقوم بجمع الأنواع التالية من المعلومات:</p>
        <ul>
          <li>
            <strong>البيانات الشخصية:</strong> معلومات يمكن أن تحدد هويتك
            شخصيًا، مثل اسمك، عنوان بريدك الإلكتروني، وتفاصيل الاتصال الخاصة بك.
          </li>
          <li>
            <strong>بيانات الاستخدام:</strong> معلومات حول كيفية تفاعلك مع
            تطبيقنا، بما في ذلك الميزات التي تستخدمها والوقت الذي تقضيه في كل
            قسم.
          </li>
          <li>
            <strong>معلومات الجهاز:</strong> بيانات متعلقة بجهازك، بما في ذلك
            نموذج الأجهزة، نظام التشغيل، ومعرفات الجهاز الفريدة.
          </li>
        </ul>
      </PrivacySection>

      <PrivacySection title="كيفية استخدامنا لمعلوماتك">
        <p>نستخدم البيانات التي نجمعها لأغراض مختلفة:</p>
        <ul>
          <li>لتقديم وصيانة خدمتنا.</li>
          <li>لتحسين وتجربة استخدامك الشخصية.</li>
          <li>لتطوير ميزات ووظائف جديدة.</li>
          <li>للتواصل معك، بما في ذلك إرسال التحديثات والمواد الترويجية.</li>
          <li>لمراقبة وتحليل الاستخدام والاتجاهات.</li>
        </ul>
      </PrivacySection>

      <PrivacySection title="مشاركة معلوماتك">
        <p>قد نشارك معلوماتك مع:</p>
        <ul>
          <li>
            <strong>شركة سعود خالد فهد السلوم للتجارة المحدودة:</strong> لعمليات
            الأعمال وتحسين الخدمات.
          </li>
          <li>
            <strong>مزودي خدمات من طرف ثالث:</strong> لتسهيل خدماتنا، تقديم
            الخدمة نيابة عنا، أداء خدمات متعلقة بالخدمة، أو مساعدتنا في تحليل
            كيفية استخدام خدماتنا.
          </li>
          <li>
            <strong>شركاء الإعلانات:</strong> لتقديم إعلانات قد تكون ذات صلة بك.
          </li>
        </ul>
      </PrivacySection>

      <PrivacySection title="الإعلانات">
        <p>
          نستخدم بياناتك لتقديم إعلانات مخصصة من خلال FluentFox وشركاء الإعلانات
          لدينا. قد يشمل ذلك عرض إعلانات بناءً على اهتماماتك ونمط استخدامك.
        </p>
      </PrivacySection>

      <PrivacySection title="الاشتراكات لإزالة الإعلانات">
        <p>
          تقدم FluentFox خيار الاشتراك الذي يسمح لك بإزالة جميع الإعلانات من
          التطبيق. من خلال الاشتراك، لن ترى أي إعلانات أثناء استخدامك لـ
          FluentFox، مما يوفر تجربة غير منقطعة.
        </p>
        <p>
          يتضمن الاشتراك لإزالة الإعلانات دفعًا متكررًا، ويمكنك إدارة اشتراكك من
          خلال إعدادات متجر التطبيقات في جهازك.
        </p>
      </PrivacySection>

      <PrivacySection title="حقوقك">
        <p>
          اعتمادًا على اختصاصك القضائي، قد تكون لديك الحقوق التالية فيما يتعلق
          ببياناتك الشخصية:
        </p>
        <ul>
          <li>
            <strong>الحق في الوصول:</strong> يمكنك طلب نسخ من بياناتك الشخصية.
          </li>
          <li>
            <strong>الحق في التصحيح:</strong> يمكنك طلب تصحيح أي معلومات تعتقد
            أنها غير دقيقة.
          </li>
          <li>
            <strong>الحق في المحو:</strong> يمكنك طلب أن نقوم بمحو بياناتك
            الشخصية.
          </li>
          <li>
            <strong>الحق في تقييد المعالجة:</strong> يمكنك طلب أن نقوم بتقييد
            معالجة بياناتك الشخصية.
          </li>
          <li>
            <strong>الحق في الاعتراض على المعالجة:</strong> يمكنك الاعتراض على
            معالجتنا لبياناتك الشخصية.
          </li>
        </ul>
      </PrivacySection>

      <PrivacySection title="تواصل معنا">
        <p>
          إذا كان لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا على:
        </p>
        <ul>
          <li>
            <strong>البريد الإلكتروني:</strong>{" "}
            <a href="mailto:support@fluentfox.com">support@fluentfox.com</a>
          </li>
          <li>
            <strong>العنوان:</strong> شركة سعود خالد فهد السلوم للتجارة
            المحدودة، 1234 شارع السوق، الرياض، المملكة العربية السعودية
          </li>
        </ul>
      </PrivacySection>
    </div>
  );
};

const Term = () => {
  return (
    <TermsContainer>
      <TermsHeader>سياسة الخصوصية</TermsHeader>
      <TermsContent>
        <p>
          نرحب بكم في موقع فلونت فوكس. باستخدام هذا الموقع، فإنك توافق على
          الالتزام بالشروط والأحكام التالية:
        </p>
        <ol>
          <li>
            المقدمة: تحدد هذه الشروط والأحكام القواعد والبنود الخاصة باستخدام
            هذا الموقع.
          </li>
          <li>
            استخدام الموقع: يجب استخدام الموقع لأغراض قانونية فقط وبطريقة لا
            تنتهك حقوق الآخرين.
          </li>
          <li>
            المحتوى: جميع المحتويات على هذا الموقع هي ملك لفلونت فوكس ولا يجوز
            نسخها أو إعادة إنتاجها بدون إذن.
          </li>
          <li>
            التعديلات: يحق لفلونت فوكس تعديل هذه الشروط والأحكام في أي وقت.
          </li>
          <li>
            القانون الواجب التطبيق: تخضع هذه الشروط والأحكام وتفسر وفقاً لقوانين
            المملكة العربية السعودية.
          </li>
          <li>
            استخدام المعلومات: باستخدام هذا الموقع وتسجيل حسابك، فإنك تمنح مالك
            فلونت فوكس الحق في استخدام معلوماتك بما في ذلك البريد الإلكتروني
            ورقم الهاتف للإعلانات وجميع الأعمال والمشاريع الخاصة به.
          </li>
          <li>
            الخصوصية:
            {/* Start of Privacy Policy Integration */}
            <PrivacyPolicy />
            {/* End of Privacy Policy Integration */}
          </li>
          <li>
            أمن الحساب: أنت مسؤول عن الحفاظ على سرية معلومات حسابك وكلمة المرور.
            نوصي بعدم مشاركة كلمة المرور الخاصة بك مع أي شخص.
          </li>
          <li>
            حدود المسؤولية: لا يتحمل فلونت فوكس أي مسؤولية عن أي أضرار مباشرة أو
            غير مباشرة تنتج عن استخدام أو عدم القدرة على استخدام الموقع أو أي
            محتوى أو خدمات ذات صلة.
          </li>
          <li>
            إنهاء الخدمة: يحتفظ فلونت فوكس بالحق في إنهاء أو تعليق وصولك إلى
            الموقع في أي وقت، دون إشعار مسبق، إذا كنت قد انتهكت هذه الشروط
            والأحكام أو إذا اعتبرنا أنك تشكل تهديدًا لموقعنا أو مستخدمينا.
          </li>
          <li>
            روابط الطرف الثالث: قد يحتوي الموقع على روابط لمواقع أخرى. نحن لسنا
            مسؤولين عن محتوى أو ممارسات الخصوصية في هذه المواقع.
          </li>
          <li>
            النزاعات: أي نزاع ينشأ عن استخدامك لهذا الموقع سيتم حله وفقًا
            لقوانين المملكة العربية السعودية.
          </li>
          <li>
            الدفع: تتم جميع عمليات الدفع من خلال متجر Shopify الخاص بنا على
            العنوان التالي:{" "}
            <a
              href="https://fluentfox.shop"
              target="_blank"
              rel="noopener noreferrer"
            >
              fluentfox.shop
            </a>
            .
          </li>
          <li>
            سياسة الاسترجاع: نظرًا لطبيعة المنتجات الرقمية، فإن جميع المبالغ
            المدفوعة غير قابلة للاسترداد.
          </li>
        </ol>
      </TermsContent>
    </TermsContainer>
  );
};

export default Term;
